import React, { useState, useCallback, useMemo, CSSProperties, useEffect } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useCustomTitle from 'hooks/useCustomTitle';
import BackToLink from 'shared-components/BackToLink';
import PackagingTemperatureInfo from 'TrackAndTrace/GenericShipmentDetails/components/PackagingTemperatureInfo';
import useAvailableHeight from 'hooks/useAvailableHeight';
import { GenericCargo, Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { LaneObject } from 'shared-components/dataTypes';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import Card from 'shared-components/Card';
import { useTheme } from '@mui/material';
import { DownloadComponentIcon } from 'shared-components/ApexTemperatureChart/icons';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import icons from 'shared-components/icons';
import useClasses from 'hooks/useClasses';
import useFetchLoggerNumbers from 'hooks/useFetchLoggerNumbers';
import CustomModal from 'shared-components/CustomModal';
import useCreateJiraTicket from 'hooks/useCreateJiraTicket';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import useGetCommonData from 'hooks/useGetCommonData';
import CommonButton from 'shared-components/CommonButton';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';
import PackagingViewOptions from './components/PackagingViewOptions/PackagingViewOptions';
import Approval from './components/Approval';
import Summary from './components/Summary';
import { DataCompleteCheckDTO, ShipmentDetailsInterface } from './lib';
import PackagingHealth from './components/PackagingHealth/PackagingHealth';
import ShipmentSteps from './components/ShipmentSteps';
import styles from './GenericShipmentDetails.style';

type Props = {
    cargo?: GenericCargo[],
    isAdmin?: boolean,
    lane?: LaneObject,
    milestones?: Milestones[],
    setRequiredOrderDataUpdate?: (requiredOrderDataUpdate: boolean) => void,
    shipment?: ShipmentDetailsInterface,
    shipmentId?: string
}

export type HiddenSeries = {
    [position: string]: boolean
}
export type ChartDataToExport = {
    [packagingSerialNumber: string]: {
        base64: string,
        chartAspectRatio: number,
        labels: {
            dataTypes: string[],
            loggerTypes: string[],
            positions: string[],
        },
        latestTemp: SensorDataItem[]
    }
}

const {
    REACT_APP_ENVIRONMENT,
} = process.env;

const downloadFileBlob = (blob: Blob, fileName: string, type: string = 'application/pdf') => {
    const fileURL = window.URL.createObjectURL(new Blob([blob], { type }));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
    window.URL.revokeObjectURL(fileURL);
};

const GenericShipmentDetails = ({
    cargo,
    isAdmin = false,
    lane,
    milestones,
    setRequiredOrderDataUpdate,
    shipment,
    shipmentId,
}: Props) => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const { setTitle } = useCustomTitle();
    const hasAccess = useHasAccess();
    const displayProductReleaseFunctionality = hasAccess(userRoles.PRODUCT_RELEASE);
    const [showTempRange, setShowTempRange] = useState(true);
    const {
        userInfo,
    } = useCurrentUserContext();
    const [downloadButtonLoading, setDownloadButtonLoading] = useState(false);
    const {
        data: dataCompleteCheck,
    } = useGetCommonData<DataCompleteCheckDTO>(
        isAdmin ? `admin/v2/shipments/${shipmentId}/access/data-complete-check`
            : `v2/shipments/${shipmentId}/data-complete-check`, {
            enabled: shipment.status === 'CLOSED',
            query: {},
        });

    const {
        Create: signPdfRequest,
    } = useSkymindBackendEndpoints(`v1${isAdmin ? '/admin' : ''}/pdf/sign`, {
        axiosResponseType: 'blob',
    }).requests;

    const checkComplete = useMemo(() => {
        const isAllComplete = dataCompleteCheck?.cargoResults?.length > 0
            && dataCompleteCheck.cargoResults.every(dcc => dcc.internalDataCompleteStatus === 'COMPLETE');
        const isSomeComplete = dataCompleteCheck?.cargoResults?.length > 0
            && dataCompleteCheck.cargoResults.some(dcc => dcc.internalDataCompleteStatus === 'COMPLETE');
        const override = dataCompleteCheck?.overrideOptions?.override;

        return {
            allComplete: override !== undefined ? override : isAllComplete,
            someComplete: override !== undefined ? override : (isSomeComplete && !isAllComplete),
        };
    }, [dataCompleteCheck, isAdmin]);

    const [showMarkers, setShowMarkers] = useState(false);
    const [showInUTC, setShowInUTC] = useState<boolean>(false);
    const [showExpTemp, setShowExpTemp] = useState(false);
    const [activePackagingSerialNumber, setActivePackagingSerialNumber] = useState<string>(null);
    const availableHeight = useAvailableHeight();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chartDataToExport, setChartDataToExport] = useState<ChartDataToExport>({});
    const [hiddenSeries, setHiddenSeries] = useState<HiddenSeries>(
        {
            AMBIENT: false,
            DOOR: null,
        },
    );
    const theme = useTheme<SkycellThemeInterface>();
    const createJiraTicket = useCreateJiraTicket();
    const { Get: getCompany } = useSkymindBackendEndpoints('companies').requests;
    const [shipperName, setShipperName] = useState<string>(null);
    const [forwarderName, setForwarderName] = useState<string>(null);
    const measurementDataDisabled = useMemo(() => shipment?.status === 'CLOSED' && checkComplete?.allComplete,
        [
            shipment?.status,
            checkComplete,
        ]);
    const { loggerNumbers } = useFetchLoggerNumbers(shipmentId, cargo, isAdmin);
    const [showMeasuredOnly, setShowMeasuredOnly] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    useEffect(() => {
        if (shipment.shipperId) {
            (async () => {
                try {
                    const rawResponse = await getCompany(shipment.shipperId);
                    const response: string = rawResponse?.data.name || null;

                    if (response) {
                        setShipperName(response);
                    }
                } catch (error) {
                    global.console.log(error);
                }
            })();
        }
        if (shipment.forwarderId) {
            (async () => {
                try {
                    const rawResponse = await getCompany(shipment.forwarderId);
                    const response: string = rawResponse?.data.name || null;

                    if (response) {
                        setForwarderName(response);
                    }
                } catch (error) {
                    global.console.log(error);
                }
            })();
        }
    }, [shipment]);

    useEffect(() => {
        setTitle(t('SENSOR_DATA.SHIPMENT_DETAILS'));
    }, [setTitle, t]);

    const onMaximize = useCallback((packagingSerial: string) => {
        setActivePackagingSerialNumber(prevSerial => (packagingSerial === prevSerial ? null : packagingSerial));
    }, []);

    const maximized = useMemo(() => {
        return Boolean(activePackagingSerialNumber);
    }, [activePackagingSerialNumber]);
    const pageStyle = useMemo<CSSProperties>(() => {
        return {
            display: 'flex',
            flexDirection: 'column',
            height: availableHeight,
        };
    }, [availableHeight]);

    const downloadPdfHandler = useCallback(async () => {
        if (shipment?.status === 'CLOSED' && checkComplete.allComplete) {
            setDownloadButtonLoading(true);
            try {
                const unsignedPdfResponse: AxiosResponse<Blob> = await axios
                    .post('https://pdf-report.dev.skymind.com/generate-pdf', {
                        cargo,
                        chartDataToExport,
                        forwarderName,
                        lane,
                        packagingLoggerNumbers: loggerNumbers,
                        shipment,
                        shipperName,
                        userInfo,
                    }, {
                        responseType: 'blob',
                    });

                const unsignedPdfBlob = unsignedPdfResponse.data;

                const formData: FormData = new FormData();

                formData.append('file', unsignedPdfBlob);

                const signed: AxiosResponse<Blob> = await signPdfRequest(formData, null);

                const fileName = `Shipment_${shipment.externalId}_report_${moment()
                    .utc(false)
                    .format('DD_MMM_YYYY_HH.mm')}.pdf`;

                downloadFileBlob(signed.data, fileName);
            } catch (error) {
                global.console.error('Error downloading the file:', error);
            } finally {
                setDownloadButtonLoading(false);
            }
        } else {
            const jiraData = {
                cargoResults: (dataCompleteCheck?.cargoResults || []).map(it => ({
                    ...it,
                    executionTimestamp: moment(it.executionTimestamp).format('DD/MM/YYYY HH:mm'),
                })),
                env: process.env.REACT_APP_ENVIRONMENT,
                packagingSerialNumber: Object.keys(dataCompleteCheck?.cargoResults || {})
                    .filter(k => {
                        return dataCompleteCheck?.cargoResults[k]
                            .internalDataCompleteStatus !== 'COMPLETE';
                    }).join(', '),
                shipmentEnd: shipment.shipmentEnd
                    ? moment(shipment.shipmentEnd).format('DD/MM/YYYY HH:mm') : 'Null',
                shipmentNumber: shipment?.externalId,
                shipmentStart: shipment.shipmentStart
                    ? moment(shipment.shipmentStart).format('DD/MM/YYYY HH:mm') : 'Null',
                user: userInfo,

            };

            await createJiraTicket(jiraData);
            handleOpenModal();
        }
    }, [
        cargo,
        chartDataToExport,
        userInfo,
        shipment,
        lane,
        checkComplete,
        dataCompleteCheck,
        t,
        shipperName,
        forwarderName,
        loggerNumbers,
    ]);

    useEffect(() => {
        if (
            shipment?.status === 'CLOSED'
            && checkComplete.allComplete
        ) setShowMeasuredOnly(true);
    }, [
        checkComplete,
    ]);
    const pdfButtonEnabled = useMemo(() => shipment?.status === 'CLOSED',
        [shipment, hasAccess]);

    return (
        <div style={pageStyle}>
            <BackToLink isGeneric to={isAdmin ? '/administration/shipments' : null} />
            <div
                className={[
                    classes.basePane,
                    maximized ? classes.maximizedPane
                        : (displayProductReleaseFunctionality
                            ? classes.panelsWrapper
                            : classes.panelsWrapperWithoutApprovalCard),
                ].join(' ')}
            >
                <CustomModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                >
                    <>
                        <p className={classes.text}>
                            Temperature data is not completely verified,
                            therefore the temperature report can&apos;t be generated yet.
                            Please try again later or contact us at support@skymind.com stating your shipment number.
                        </p>
                        <CommonButton
                            sx={{ margin: 'auto' }}
                            variant="text"
                            onClick={handleCloseModal}
                        >
                            OK
                        </CommonButton>
                    </>
                </CustomModal>
                {shipment?.status === 'NOT_STARTED' && !hasAccess(userRoles.TEMPERATURE_CHECK)
                    ? (
                        <Card className={`${classes.cardNotStarted} ${classes.panelElement}`}>
                            <img
                                alt="icon"
                                className={classes.icon}
                                src={icons.info_blue}
                            />
                            <p className={classes.text}>
                                This shipment has not yet started.
                                The packaging information and sensor data will show up once the shipment has started.
                            </p>
                            <img
                                alt="icon"
                                className={classes.iconEnd}
                                src={icons.info_blue}
                            />
                        </Card>
                    )
                    : ('')}
                <Summary
                    className={`${classes.summary} ${classes.panelElement}`}
                    destinationLabel="Destination Airport"
                    destinationValue={shipment.destinationAirport}
                    forwarderName={forwarderName}
                    lane={lane}
                    originLabel="Origin Airport"
                    originValue={shipment.originAirport}
                    shipment={shipment}
                    shipmentEnd={shipment.shipmentEnd}
                    shipmentStart={shipment.shipmentStart}
                    shipperName={shipperName}
                    title={`${t('COMMON.SHIPMENT')} ${shipment?.externalId}`}
                />
                {
                    displayProductReleaseFunctionality && false && (
                        <Approval
                            className={`${classes.approval} ${classes.panelElement}`}
                            dataUpdateTrigger={setRequiredOrderDataUpdate}
                            packagings={shipment?.packagings}
                            shipmentEnd={shipment.shipmentEnd}
                            shipmentNumber={shipment?.externalId}
                            shipmentStart={shipment.shipmentStart}
                            skyCoreId={shipment?.skyCoreId}
                            onCsvButton={() => {
                            }}
                        />
                    )
                }
                {
                    !maximized && (
                        <div className={classes.orderSteps}>
                            {
                                ((((REACT_APP_ENVIRONMENT === 'dev'
                                    || REACT_APP_ENVIRONMENT === 'test')
                                    && Number(userInfo?.attributes?.companyId) === 14)
                                || ((REACT_APP_ENVIRONMENT === 'pre'
                                        || REACT_APP_ENVIRONMENT === 'prod')
                                    && (Number(userInfo?.attributes?.companyId) === 39
                                            || Number(userInfo?.attributes?.companyId) === 132)))
                                || isAdmin) && (
                                    <Card
                                        className={[classes.card, classes.downloadReportCard].join(' ')}
                                        title={t('TRACK_AND_TRACE.DATA_REPORTS')}
                                        titleClass={classes.title}
                                        zeroSidePadding
                                        zeroVerticalPadding
                                    >
                                        <div className={classes.downloadButtons}>
                                            <CommonTooltip
                                                description={t('TRACK_AND_TRACE.PDF_BUTTON_DISABLED_LABEL')}
                                                enabled={!pdfButtonEnabled}
                                                type={TOOLTIP_TYPE.SHRINK}
                                            >
                                                <span>
                                                    <CommonButton
                                                        disabled={!pdfButtonEnabled || downloadButtonLoading}
                                                        loading={downloadButtonLoading}
                                                        startIcon={
                                                            <DownloadComponentIcon color={theme.palette.common.white} />
                                                        }
                                                        onClick={downloadPdfHandler}
                                                    >
                                                        PDF
                                                    </CommonButton>
                                                </span>
                                            </CommonTooltip>
                                        </div>
                                    </Card>
                                )
                            }

                            {
                                shipment?.shipmentSteps?.length !== 0 && (
                                    <ShipmentSteps
                                        key={shipment?.externalId}
                                        milestones={milestones}
                                    />
                                )
                            }
                        </div>
                    )
                }
                <div className={classes.packagingDetailsWrapper}>
                    {
                        !maximized && (shipment?.status !== 'NOT_STARTED'
                            || hasAccess(userRoles.TEMPERATURE_CHECK)) && (
                            <PackagingHealth
                                cargo={cargo}
                                packagings={cargo?.map(item => item.packaging)}
                            />
                        )
                    }
                    {
                        (shipment?.status !== 'NOT_STARTED' || hasAccess(userRoles.TEMPERATURE_CHECK))
                        && (
                            <PackagingViewOptions
                                hiddenSeries={hiddenSeries}
                                laneId={shipment.laneId}
                                milestones={lane?.milestones}
                                packagings={cargo?.map(item => item.packaging)}
                                setShowAmbientTemp={
                                    () => setHiddenSeries(prev => ({ ...prev, AMBIENT: !prev.AMBIENT }))
                                }
                                setShowDoors={
                                    () => setHiddenSeries(prev => ({ ...prev, DOOR: !prev.DOOR }))
                                }
                                setShowExpTemp={setShowExpTemp}
                                setShowInUTC={setShowInUTC}
                                setShowMarkers={setShowMarkers}
                                setShowMeasuredOnly={setShowMeasuredOnly}
                                setShowTempRange={setShowTempRange}
                                showAmbientTemp={!hiddenSeries.AMBIENT}
                                showDoors={!hiddenSeries.DOOR}
                                showExpTemp={showExpTemp}
                                showInUTC={showInUTC}
                                showMarkers={showMarkers}
                                showMeasuredOnly={showMeasuredOnly}
                                showMeasuredOnlyDisabled={measurementDataDisabled}
                                showTempRange={showTempRange}
                                status={shipment.status}
                            />
                        )
                    }
                    {shipment?.status !== 'NOT_STARTED' || hasAccess(userRoles.TEMPERATURE_CHECK) ? cargo
                        .map((item) => item.packaging)
                        ?.filter((packaging) => !activePackagingSerialNumber
                            || (packaging?.serialNumber === activePackagingSerialNumber))
                        ?.map((packaging, index) => {
                            return (
                                <PackagingTemperatureInfo
                                    key={`packagingTemperatureInfo_${packaging?.serialNumber}`}
                                    cargo={cargo.find(item => item.packaging === packaging)}
                                    closed={shipment?.status === 'CLOSED'}
                                    energyLevel={cargo[index]?.energyLevelCheckResult?.remainingEnergyLevel}
                                    exportPng={(base64, aspectRatio) => {
                                        setChartDataToExport(prev => ({
                                            ...prev,
                                            [packaging.serialNumber]: {
                                                ...prev[packaging.serialNumber],
                                                base64,
                                                chartAspectRatio: aspectRatio,
                                            },
                                        }));
                                    }}
                                    exportTempData={(serialNumber, sd, labels) => {
                                        setChartDataToExport(prev => ({
                                            ...prev,
                                            [serialNumber]: {
                                                base64: prev[serialNumber]?.base64,
                                                chartAspectRatio: prev[serialNumber]?.chartAspectRatio,
                                                labels,
                                                latestTemp: sd,
                                            },
                                        }));
                                    }}
                                    hiddenSeries={hiddenSeries}
                                    isAdmin={isAdmin}
                                    maximized={maximized}
                                    milestones={milestones}
                                    packaging={packaging}
                                    progressUpdateEnabled={
                                        (Number(userInfo?.attributes?.companyId) === shipment?.forwarderId)
                                        && (shipment?.milestoneSource === 'LANE')
                                    }
                                    setHiddenSeries={setHiddenSeries}
                                    setRequiredOrderDataUpdate={setRequiredOrderDataUpdate}
                                    shipmentEnd={shipment?.shipmentEnd}
                                    shipmentId={shipmentId}
                                    shipmentNumber={shipment?.externalId}
                                    shipmentStart={shipment?.shipmentStart}
                                    showExpTemp={showExpTemp}
                                    showInUTC={showInUTC}
                                    showMarkers={showMarkers}
                                    showMeasuredOnly={shipment.status === 'CLOSED' && checkComplete.allComplete
                                        ? true : showMeasuredOnly}
                                    showTempRange={showTempRange}
                                    timeRange={shipment?.shipmentSensorDataTimeRange}
                                    transport={shipment?.status === 'IN_TRANSIT'}
                                    unloadingTimestamp={shipment?.unloadingTimestamp}
                                    onMaximize={onMaximize}
                                />
                            );
                        }) : ''}
                </div>
            </div>
        </div>
    );
};

export default GenericShipmentDetails;
