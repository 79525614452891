import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { documentLocationUrl } from 'shared-components/constants';
import { AuthContext } from 'Contexts/AuthContext';
import { openSupport } from 'utils/jiraHelpDesk';

export const KnowledgeBase = ({
    setEnableGuide,
}) => {
    const iframeRef = useRef(null);
    const { userInfo } = useContext(AuthContext);
    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();

    const getPrefix = () => {
        if (documentLocationUrl.includes('localhost')) return '.dev';

        const [, prefix] = documentLocationUrl.split('.');

        if (prefix === 'skymind' || prefix === 'pre') {
            return '';
        } else if (prefix === 'poc') {
            return '.test';
        } else return `.${prefix}`;
    };
    const jiraHelpDeskCallback = useCallback(() => openSupport(() => {
        setErrorStatus('Jira extension failed to load.');
    }), []);

    const KnBaseUrl = `https://knowledge${getPrefix()}.skymind.com/`;

    useEffect(() => {
        const handleIframeLoad = () => {
            if (iframeRef.current) {
                const message = { userRoles: userInfo.assignedRoles };

                iframeRef.current.contentWindow.postMessage(message, '*');
                iframeRef.current.contentWindow.postMessage({ type: 'triggerAutofocus' }, '*');
            }
        };

        if (iframeRef.current && userInfo.assignedRoles.length) {
            iframeRef.current.onload = handleIframeLoad;
            handleIframeLoad();
        }
    }, [userInfo.assignedRoles.length]);

    useEffect(() => {
        const handleMessage = (_event: any) => {
            if (_event.data === 'callContactSupport') {
                jiraHelpDeskCallback();
                setEnableGuide(false);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <iframe ref={iframeRef} src={KnBaseUrl} title="Knowledge Base" />
    );
};
